import { useConfiguration } from '@arvesta-websites/configuration';
import { HeroBannerCustomMask, HeroBannerMask } from '@arvesta-websites/icons';
import { tv } from 'tailwind-variants';

import { withErrorBoundary } from '../../components';

const overlayStyles = tv({
  slots: {
    overlay: 'absolute w-full h-full top-0 left-0 mt-px z-10',
    overlayCustom: 'absolute bottom-0 w-full',
    overlaySVGCustom: 'absolute bottom-0 w-full',
  },
  variants: {
    device: {
      desktop: { overlay: 'hidden lg:block', overlayCustom: 'hidden lg:block', overlaySVGCustom: 'hidden md:block' },
      mobile: { overlay: 'block md:hidden', overlayCustom: 'block md:hidden', overlaySVGCustom: 'block md:hidden' },
      tablet: {
        overlay: 'hidden md:block lg:hidden',
        overlayCustom: 'hidden md:block lg:hidden',
        overlaySVGCustom: 'hidden md:block lg:hidden',
      },
    },
  },
});

type propsType = {
  partOfCarousel?: boolean;
  overlayDesktop?: string;
  overlayMobile?: string;
  overlayTablet?: string;
};

const BannerOverlay = ({ partOfCarousel, overlayDesktop, overlayMobile, overlayTablet }: propsType) => {
  const config = useConfiguration();

  const { overlayCustom, overlaySVGCustom, overlay } = overlayStyles();

  //Temporary solution for Proxani - TWDS-442
  if (process.env.BRAND_TAG === 'brand_proxani') {
    return null;
  }

  if (overlayDesktop && config.heroBanner.customOverlay) {
    return (
      <>
        <div className={overlaySVGCustom({ device: 'desktop' })}>
          <img src={overlayDesktop} alt="Custom Overlay" className="w-full" />
        </div>
        <div className={overlaySVGCustom({ device: 'tablet' })}>
          <img src={overlayTablet} alt="Custom Overlay" className="w-full" />
        </div>
        <div className={overlaySVGCustom({ device: 'mobile' })}>
          <img src={overlayMobile} alt="Custom Overlay" className="w-full" />
        </div>
      </>
    );
  }

  if (config.heroBanner.customMask && !partOfCarousel) {
    return (
      <>
        <div className={overlayCustom({ device: 'desktop' })}>
          <HeroBannerCustomMask.Desktop className="w-full" />
        </div>
        <div className={overlayCustom({ device: 'tablet' })}>
          <HeroBannerCustomMask.Tablet className="w-full" />
        </div>
        <div className={overlayCustom({ device: 'mobile' })}>
          <HeroBannerCustomMask.Mobile className="w-full" />
        </div>
      </>
    );
  }

  if (config.heroBanner?.mask && !partOfCarousel) {
    return (
      <>
        <div className={overlay({ device: 'desktop' })}>
          <HeroBannerMask.Desktop />
        </div>
        <div className={overlay({ device: 'tablet' })}>
          <HeroBannerMask.Tablet />
        </div>
        <div className={overlay({ device: 'mobile' })}>
          <HeroBannerMask.Mobile />
        </div>
      </>
    );
  }

  return null;
};

export default withErrorBoundary(BannerOverlay, { componentName: 'BannerOverlay' });
