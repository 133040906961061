import { getDestination } from '../../../../utils';
import { withErrorBoundary } from '../../../components';

import {
  CardTitle,
  CardWrapper,
  Gradient,
  LocationWrapper,
  StyledChevron,
  StyledDate,
  StyledImage,
  StyledLocation,
  TitleWrapper,
} from './Styled';

const heroOrImage = (item: any) => {
  if (item.hero) {
    return {
      altText: item.hero.image.altText,
      title: item.hero.image.title,
      gatsbyImageData: item.hero.image.asset.gatsbyImageData,
    };
  } else {
    return {
      altText: item.image.altText,
      title: item.image.title,
      gatsbyImageData: item.image.asset.gatsbyImageData,
    };
  }
};

const BottomCard = ({ item, inColumn }: { item: any; inColumn?: boolean }) => {
  const { title, id, cardLink, startDate, location, slug } = item;

  const image = heroOrImage(item);
  const originalDate = new Date(startDate);
  const formattedDate = `${originalDate.getDate()}/${originalDate.getMonth() + 1}/${originalDate.getFullYear()}`;
  return (
    <CardWrapper key={id} to={(slug || cardLink?.externalUrl) ?? getDestination(cardLink?.internalEntry)}>
      <LocationWrapper>
        {startDate && <StyledDate>{formattedDate}</StyledDate>}
        {location && <StyledLocation>{location?.title}</StyledLocation>}
      </LocationWrapper>
      <TitleWrapper inColumn={inColumn}>
        <CardTitle>{title}</CardTitle>
        <StyledChevron />
      </TitleWrapper>
      <StyledImage alt={image?.altText ? image.altText : title} image={image?.gatsbyImageData} objectFit="cover" />
      <Gradient />
    </CardWrapper>
  );
};

export default withErrorBoundary(BottomCard, { componentName: 'MosaicSectionModule/BottomCard' });
